<template>
    <v-alert
        icon="mdi-account-alert"
        border="top"
        color="secondary"
        dismissible
        prominent
        dark
    >
        <slot></slot>
    </v-alert>
</template>
<script>
export default {
    name: "mdi-account-alert",
    components: {},
    props: {},
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>